import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { MdArrowBack } from "@react-icons/all-files/md/MdArrowBack"
import { MdArrowForward } from "@react-icons/all-files/md/MdArrowForward"

import Layout from "../components/Layout"
import { Header, Seo } from "../components/common"
import { PageProps } from "@/definitions"

const BlogPostTemplate: React.FC<PageProps> = ({ data, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article itemScope itemType="http://schema.org/Article">
        <Header
          title={post.frontmatter.title || post.fields.slug}
          date={post.frontmatter.date}
        />
        <hr />

        <section
          itemProp="articleBody"
          className="prose lg:prose-lg xl:prose-xl mt-8 mx-auto"
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </section>
      </article>
      {/* TODO FIXME re-enable nav footer after getting previous/next to match - see gatsby-node.js TODO FIXME*/}
      { false && <nav className="mt-8 grid grid-cols-blog">
        <ul className="col-start-2 text-lg flex flex-wrap justify-between">
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <MdArrowBack className="post-nav-arrow" />
                {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title}{" "}
                <MdArrowForward className="post-nav-arrow" />
              </Link>
            )}
          </li>
        </ul>
      </nav>}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
